.section-personnalise {
    display: flex;
    height: 400px;
    width: 100%;
    font-family: 'Handlee';
}

.bouquet-perso-section {
    position: relative;
    width: 50%;
    background-color: #292026;
    transition: transform 1s ease;
}

.abonnement-perso-section {
    position: relative;
    width: 50%;
    background-color: #F4EFDE;
    transition: transform 1s ease;
}

.bouquet-perso-section .slider-container {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    width: 100%;
    height: 100%;
    color: #F4EFDE;
    background-color: #292026;
}

.abonnement-perso-section .slider-container {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    color: #292026;
    z-index: 3;
    width: 100%;
    height: 100%;
}

.bouquet-perso-section .section-text-perso {
    position: absolute;
    background-color: #292026;
    top: 0;
    opacity: 0;
    color: #F4EFDE;

    width: 100%;
    height: 400px;
    z-index: -1;
    transition: transform 1s ease, opacity 3s;
}

.abonnement-perso-section .section-text-perso {
    position: absolute;
    background-color: #F4EFDE;
    color: #292026;
    top: 0;
    opacity: 0;
    width: calc(100% + 5px);
    height: 400px;
    z-index: -1;
    transition: transform 1s ease, opacity 3s;
}

.abonnement-perso-hover {
    transform: translateX(100%);
    transition: transform 1s ease;
}

.bouquet-perso-section:hover .section-text-perso {
    transform: translateX(100%);
    transition: transform 1s ease, opacity 0.01ms linear;
    opacity: 1;
}

.bouquet-perso-hover {
    transform: translateX(-100%);
    transition: transform 1s ease;
}

.abonnement-perso-section:hover .section-text-perso {
    transform: translateX(-99%);
    transition: transform 1s ease, opacity 0.01ms linear;
    opacity: 1;
}

.abonnement-perso-section .opacity-section {
    opacity: 0;
    transition: none
}

.bouquet-perso-section .opacity-section {
    opacity: 0;
    transition: none
}

.section-personnalise .titre-section {
    font-size: 1.9rem
}

.section-text-perso {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-slider {
    width: 250px;
    height: 250px;
    border: 4px solid #7dc75A;
    border-radius: 30px;
    object-fit: cover;

}


.section-personnalise .slider {
    display: flex;
    width: 85%;
    align-items: center;
    justify-content: space-around;
}

.arrow-slider {
    width: 5%;
}

.text-content-perso {
    display: flex;
    white-space: pre-line;
    text-align: center;
    width: 80%;
    font-size: 1.6rem;

}

@media screen and (max-width: 1080px) {
    .text-content-perso {
        font-size: clamp(1rem, 2.8vw, 1.4rem);
    }
}

@media screen and (max-width: 780px) {
    .section-personnalise {
        display: flex;
        height: 800px;
        width: 100%;
        font-family: 'Handlee';
        flex-direction: column;
    }

    .bouquet-perso-section {
        position: relative;
        width: 100%;
        height: 400px;
        background-color: #292026;
        transition: transform 1s ease;
    }

    .abonnement-perso-section {
        position: relative;
        width: 100%;
        height: 400px;
        background-color: #F4EFDE;
        transition: transform 1s ease;
    }

    .bouquet-perso-section .section-text-perso {
        position: absolute;
        background-color: #292026;
        top: 0;
        opacity: 1;
        color: #F4EFDE;
        left: -100%;
        width: 100%;
        height: 400px;
        z-index: -1;
        transition: transform 1s ease, opacity 3s;
    }

    .abonnement-perso-section .section-text-perso {
        position: absolute;
        background-color: #F4EFDE;
        color: #292026;
        top: 0;
        opacity: 1;
        left: -100%;
        width: calc(100% + 5px);
        height: 400px;
        z-index: -1;
        transition: transform 1s ease, opacity 3s;
    }

    /*-----------*/
    .bouquet-perso-hover-mobile {

        transform: translateX(100%);
        transition: transform 1s ease;
    }

    .bouquet-perso-section:hover .section-text-perso {
        transform: translateX(0);
        transition: transform 1s ease, opacity 0.01ms linear;
        opacity: 1;
    }


    /*-------*/
    .abonnement-perso-hover-mobile {

        transform: translateX(100%);
        transition: transform 1s ease;
    }

    .abonnement-perso-section:hover .section-text-perso {
        transform: translateX(0);
        transition: transform 1s ease, opacity 0.01ms linear;
        opacity: 1;
    }



    .arrow-mobile {
        width: 20px;
        transform: rotateY(180deg);
        position: absolute;
        top: 40px;
        left: -50px;

    }

    .container-title-section-perso {
        position: relative;
    }


}

@media screen and (max-width: 480px) {
    .arrow-mobile {
        width: 20px;
        transform: rotateY(180deg);
        position: absolute;
        top: 30px;
        left: -40px;
    }

    .section-personnalise .titre-section {
        font-size: 1.5rem
    }
}