.section-evenement{
    width: 100%;
    position: relative;
    margin-bottom: 30px ;
    
}

.intro-evenements{
    text-align: center;
    font-family: 'Handlee';
    font-size: clamp(15px, 1.5vw, 25px)
}
.section-evenement1{
    height: clamp(300px, 50vw, 590px);
    width: 100%;
    display: flex;
    flex-direction: column;
}
.section-evenement2{
    height: clamp(300px, 50vw, 570px);

    width: 100%;
    display: flex;
    flex-direction: column;
}
.container-titre-evenmenement{
    width: 100%;
    margin: 20px 0;
}
.titre-evenmenement{
    text-align: center;
    font-family: 'Handlee';
    font-weight: 500;
    font-size: clamp(30px, 5vw, 50px);
    margin: 0 0 20px 0;
    
}

.contenu{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.img-evenement{
    width: clamp(100px, 40vw, 600px);
    height: clamp(100px, 27vw, 420px);
    border: solid 5px #292026;
    border-radius: 15px;
    object-fit: cover;
}

.text-evenement{
    width :clamp(50px, calc(50vw - 40px), 450px);
    height: fit-content;
    margin: 0;
    padding: 5px 20px;
    font-family: 'Handlee';
    font-size: clamp(2px,1.8vw,20px);
    line-height: 1.8em;
    background-color: #F4EFDE;
    border-radius: 20px;
    border: solid 2px;
}

.section-evenement .line1{
    position: absolute;
    top: 0%;
    left: auto;
    z-index: -1;
    transform: rotate(30deg);
    width: 110%;
}
.section-evenement .line2{
    position: absolute;
    top: 50%;
    left: auto;
    z-index: -1;
    transform: rotate(30deg);
    width: 100%;
}

@media screen and (max-width: 780px) {
    .section-evenement1{
        height: clamp(200px, 45vw, 400px);
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .section-evenement2{
        height: clamp(200px, 45vw, 400px);
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .titre-evenmenement{
        text-align: center;
        font-family: 'Handlee';
        font-weight: 500;
        font-size: clamp(20px, 5vw, 40px);
        margin: 0 ;
        
    }
}