.copyright {
    background-color: #292026;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.copyright * {
    position: relative;
    color: white;
    font-size: 0.8em;
}

.copyright a {
    padding-left: 5px;
    text-decoration: none;
}

@media (max-width: 480px) {
    .copyright * {
        font-size: 0.6em;
    }
}