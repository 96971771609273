.container-bandeau {
    position: relative;
    height: 150px;
    padding-top: 0 ;
}

.container-bandeau img {
    position:absolute;
    z-index: 2;
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.activity-text {
    position: absolute;
    z-index: 2;
    top: 25%;
    margin: 0;
    font-family: 'Amatic SC';
    font-size: 400%;
    color: white;
    display: flex;
    width: 100%;
    justify-content: center;
}

@media (max-width: 480px) {
    .activity-text{
        top: 70px;
        font-size: 15vw;
    }

    .container-bandeau{
        padding-top: 15px;
    }
}

@font-face {
    font-family: 'Amatic SC';
    src: url(../Fonts/AmaticSC-Bold.ttf) format('truetype'),url(../Fonts/AmaticSC-Bold.woff) format('woff');
}

@media (max-width: 480px) {
    .activity-text {
        top: 50%;
        font-size: 300%;
    }
}