.partenaire-section{
    display: flex;
    justify-content: space-around;
    padding:20px 50px;
    flex-wrap: wrap;
    gap: 10px clamp(5px, 10vw, 25px);
    background-color: #FFFDF4;
}
.partenaire1{
    width: 150px;
    height: auto;
    object-fit: contain;
}

.partenaire2{
    width: 100px;
    height: auto;
    object-fit: contain;
}
.partenaire3{
    width: 100px;
    height: auto;
    object-fit: contain;
}
.partenaire4{
    width: 70px;
    height: auto;
    object-fit: contain;
}
.partenaire5{
    width: 120px;
    height: auto;
    object-fit: contain;
}
.partenaire6{
    width: 70px;
    height: auto;
    object-fit: contain;
}
.partenaire7{
    width: 70px;
    height: auto;
    object-fit: contain;
}
.partenaire8{
    width: 70px;
    height: auto;
    object-fit: contain;
}