.contact-content {
    display: flex;
}

.contact-section {
    padding-bottom: 5%;
}

.contact-center-element {
    margin: 0 auto;
    width: 80%;
    max-width: 1024px;
    padding: 0 1.25rem;
    display: flex;
    flex-direction: column;
}

.contact-form-background {
    padding: 5% 10%;
    background-color: #292026;
    position: relative;
    border-radius: 50px;
}

.contact-column {
    padding: 0 20px;
    flex-basis: 50%;
}

.contact-textbox {
    padding: 1.3125rem 1.5625rem 1.3125rem 1.25rem;
    background-color: #FFFDF4;
    
  
}

select.contact-textbox {
    appearance: none; /* Supprime le style par défaut du navigateur */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../images/icon__arrow_down_.svg"); /* Remplacez par le chemin de votre image */
  background-size: 50px; /* Taille de l'image de la flèche */
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 50px;
}

.contact-messagebox {
    height:100px;
    padding: 1.3125rem 1.5625rem 1.3125rem 1.25rem;
    background-color: #FFFDF4;
}

.contact-textbox-label {
    font-family: 'Handlee';
    margin: 0 0 5px;
    color: #FFFDF4;
}

.contact-box-component {
    display: flex;
    flex-direction: column;
    margin: 0 0 45px;
}

.contact-button {
    font-family: "Handlee";
    padding: 20px 30px;
    color: white;
    background-color: #7DC75A;
    transform: translate(0%, -30%);
}

.contact-button-clicked {
    font-family: "Handlee";
    padding: 20px 30px;
    color: white;
    background-color: #D398BB;
    transform: translate(0%, -30%);
}

.contact-button-content {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 60%;
}

@media screen and (max-width: 480px) {
    .contact-button-content {
        width: 90%;
    }
}

.contact-button-content span {
    font-family: "Handlee";
    color: white;
}

.contact-button-content-center {
    display: flex;
    justify-content: center;
}

@font-face {
  font-family: 'Handlee';
  src: url(../Fonts/Handlee-Regular.ttf) format('truetype'), url(../Fonts/Handlee-Regular.woff) format('woff');
}

@media screen and (max-width: 1023px) {
    .contact-content {
        flex-direction: column;
    }
}

@media screen and (min-width: 1024px){
    .contact-content {
        flex-direction: row;
    }
}

.contact-text-lead {
  font-family: 'Handlee';
  font-size: 1.5em;
  line-height: 2.125rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #c6c6c6;
}

.contact-text-lead-2 {
  font-family: 'Handlee';
  font-size: 1.5em;
  line-height: 2.125rem;
  margin: -12px 0 24px 0;
}
.valid-email-text {
    font-size: 1.2em;
    color: #7DC75A;
}
.invalid-email-text {
    font-size: 1.4em;
    font-weight: 300;
    color: #D398BB;
}

.contact-option {
    width: 80vw;
}

@media screen and (max-width: 480px) {
    .contact-text-lead,
    .contact-text-lead-2 {
        font-size: 1.3em;
    }
    .collect-form-background {
        padding: 5% 2%;

    }
    .container-validate-product{
        display: flex;
        height: 100%; 
    }

}

@media screen and (max-width: 380px) {
    .contact-text-lead,
    .contact-text-lead-2 {
        font-size: 1.1em;
    }
}
