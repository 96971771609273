.storytelling-card {
    width: 80%;
    margin: 5% auto;
    background-color: #52414C;
    max-width: 1200px;
    border-radius: 50px;
}

.storytelling-info-right {
    display: flex;
    flex-direction: row;
    padding: 5% 5% 8% 5%;
}

.storytelling-info-left {
    display: flex;
    flex-direction: row;
    padding: 5% 5% 8% 5%;
}

.storytelling-img-container {
    display: flex;
    flex-basis: 40%;
    margin: 2%;
    z-index: 1;
}

.storytelling-info-left .storytelling-img {
    width: 100%;
    object-fit: cover;
    border-radius: 50px;
    border: 0.5rem solid #7DC75A;
}

.storytelling-info-right .storytelling-img {
    width: 100%;
    object-fit: cover;
    border-radius: 50px;
    border: 0.5rem solid #D398BB;
}

.storytelling-titled-text {
    flex-basis: 60%;
    margin: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.storytelling-titled-text * {
    color: #FFFDF4;
}

.storytelling-left-textcard .storytelling-titled-text p {
    font-family: "Handlee";
    font-size: 1.9em;
    line-height: 2em;
    margin: 9% 0;
    text-align: justify;
}

.storytelling-right-textcard .storytelling-titled-text p {
    font-family: "Handlee";
    font-size: 1.9em;
    line-height: 2em;
    margin: 9% 0;
    text-align: justify;
}

.storytelling-info-right .storytelling-titled-text p {
    font-family: "Handlee";
    font-size: 1.9em;
    line-height: 2em;
    margin: 9% 0;
    text-align: justify;
}

.storytelling-info-left .storytelling-titled-text p {
    font-family: "Handlee";
    font-size: 1.9em;
    line-height: 2em;
    margin: 9% 0;
    text-align: justify;
}

.storytelling-info-right .storytelling-titled-text span {
    background-color: #D398BB;
    font-family: "Amatic SC";
    font-size : 3.5rem;
    padding: 2% 15%;
    border-radius: 1000px;
    text-align: center;
}

.storytelling-info-left .storytelling-titled-text span {
    background-color: #7DC75A;
    font-family: "Amatic SC";
    font-size : 3.5rem;
    padding: 2% 15%;
    border-radius: 1000px;
    text-align: center;
}

.storytelling-info-right .storytelling-titled-text a button {
    background-color: #D398BB;
    font-family: "Amatic SC";
    font-size : 3rem;
    padding: 5% 50px;
    border-radius: 1000px;
}

.storytelling-info-left .storytelling-titled-text a button {
    background-color: #7DC75A;
    font-family: "Amatic SC";
    font-size : 3rem;
    padding: 5% 50px;
    border-radius: 1000px;
}

/* Sous-pages */
.storytelling-left-textcard {
    width: 80%;
    margin: 2% 20% 4% 0;
    background-color: #52414C;
    border-radius: 50px;
    border: 0.75rem solid white;
    transform: translate(-5%, 0%);
    text-align: justify;
}

.storytelling-center-textcard {
    width: 80%;
    margin: 20% auto 10%;
    background-color: #52414C;
    border-radius: 50px;
    border: 0.75rem solid white;
    text-align: justify;
}

.storytelling-center-textcard p {
    white-space: pre-line
}

.storytelling-right-textcard {
    width: 80%;
    margin: 2% 0 4% 20%;
    background-color: #52414C;
    border-radius: 50px;
    border: 0.75rem solid white;
    transform: translate(5%, 0%);
    text-align: justify;
}

.storytelling-left-textcard div {
    padding-left: 5%;
}

.storytelling-right-textcard div {
    padding-right: 5%;
}

.a-propos-sub-page-title-1 {
    background-color: #D398BB;
    font-family: "Amatic SC";
    font-size: 4.5rem;
    padding: 2% 0%;
    margin: 5% 38% 0;
    border-radius: 1000px;
    text-align: center;
    color: white;
    z-index: 1;
}

.a-propos-sub-page-title-2 {
    background-color: #7DC75A;
    font-family: "Amatic SC";
    font-size: 4.5rem;
    padding: 2% 0%;
    margin: 5% 38% 0;
    border-radius: 1000px;
    text-align: center;
    color: white;
    z-index: 1;
}

.a-propos-ss-page{
    display: flex;
    flex-direction: column;
    position: relative;
}

.a-propos-ss-page-computer{
    display: flex;
    flex-direction: column;
    position: relative;
}

.a-propos-ss-page-phone{
    display: flex;
    position: relative;
}

.a-propos-ss-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0.15;
  z-index: -1;
}

.demo-bg {
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
}

.story-svg-holder{
    position: relative;
}

/*decoration*/
.deco-story-palm-1{
    position: absolute;
    width:60%;
    top: -25px;
    left: 25%;
    z-index: 0;
    transform: rotate(-14deg) scale(-1,1);
}

.deco-story-palm-2{
    position:absolute;
    width:33%;
    top: 140px;
    left: 60%;
    z-index: 0;
    transform: rotate(50deg);
}

.deco-story-palm-3{
    position:absolute;
    top: -106px;
    left: 16%;
    z-index: 0;
    transform: rotate(-8deg);
}

.deco-story-palm-4{
    position:absolute;
    width:40%;
    top: -314px;
    left: 24%;
    z-index: 0;
    transform: rotate(150deg);
}

.deco-story-palm-5{
    position:absolute;
    width: 50%;
    top: -125px;
    left: 31%;
    z-index: 0;
}

.deco-story-palm-6{
    position:absolute;
    width: 18%;
    top: -226px;
    left: 65%;
    z-index: 0;
}

.deco-story-palm-7{
    position:absolute;
    width: 30%;
    top: -125px;
    left: 52%;
    z-index: 0;
}

.deco-story-palm-8{
    position:absolute;
    width: 28%;
    top: -132px;
    left: 51%;
    z-index: 0;
}

.deco-story-palm-9{
    position:absolute;
    width: 35%;
    top: -355px;
    left: -16%;
    z-index: 0;
    transform: rotate(95deg);
}

.deco-story-palm-10{
    position:absolute;
    width: 36%;
    top: -497px;
    left: -20%;
    z-index: 0;
    transform: rotate(85deg);
}

/*Storytelling page*/
@media screen and (min-width: 1024px) {
    .a-propos-ss-page-phone{
        display: none;
    }
}

@media screen and (max-width: 1023px) {
    .a-propos-ss-page-computer{
        display: none;
    }

    .a-propos-sub-page-title-1,
    .a-propos-sub-page-title-2 {
        font-size: 2.5rem;
        margin: 5% 33% 0;
    }

    .storytelling-center-textcard{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .storytelling-center-textcard .storytelling-titled-text p {
        font-family: "Handlee";
        font-size: 1.5em;
        line-height: 1.8em;
        text-align: justify;
    }

    .deco-story-page-1{
        position: absolute;
        width: 650px;
        top: 6px;
        left: 5%;
        z-index: 0;
        transform: rotate(-103deg);
    }

    .deco-story-page-2{
        position: absolute;
        width: 180px;
        top: 77px;
        left: 77%;
        z-index: 0;
        transform: rotate(156deg);
    }

    .deco-story-page-3{
        position: absolute;
        width: 310px;
        top: 566px;
        left: 53%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-4{
        position: absolute;
        width: 425px;
        top: -36px;
        left: 28%;
        z-index: 0;
        transform: rotate(-33deg) scale(-1,1);
    }

    .deco-story-page-5{
        position: absolute;
        width: 250px;
        top: 551px;
        left: 24%;
        z-index: 0;
        transform: rotate(188deg) scale(-1,1);
    }

    .deco-story-page-6{
        position: absolute;
        width: 690px;
        top: 260px;
        left: 59%;
        z-index: 0;
        transform: rotate(85deg);
    }

    .deco-story-page-7{
        position: absolute;
        width: 227px;
        top: -36px;
        left: 44%;
        z-index: 0;
        transform: rotate(63deg);
    }

    .deco-story-page-8{
        position: absolute;
        width: 564px;
        top: 376px;
        left: 13%;
        z-index: 0;
        transform: rotate(172deg);
    }

    .deco-story-page-9{
        position: absolute;
        width: 310px;
        top: 524px;
        left: 53%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-10{
        position: absolute;
        width: 804px;
        top: 409px;
        left: 17%;
        z-index: 0;
    }

    .deco-story-page-11{
        position: absolute;
        width: 300px;
        top: -36px;
        left: 72%;
        z-index: 0;
        transform: rotate(-40deg) scale(-1,1);
    }

    .deco-story-page-12{
        position: absolute;
        width: 450px;
        top: -105px;
        left: 17%;
        z-index: 0;
        transform: rotate(-155deg);
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px){
    .deco-story-page-1{
        position: absolute;
        width: 600px;
        top: -8px;
        left: -13%;
        z-index: 0;
        transform: rotate(-103deg);
    }

    .deco-story-page-2{
        position: absolute;
        width: 136px;
        top: 36px;
        left: 84%;
        z-index: 0;
        transform: rotate(156deg);
    }

    .deco-story-page-3{
        position: absolute;
        width: 281px;
        top: 606px;
        left: 50%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-4{
        position: absolute;
        width: 365px;
        top: -38px;
        left: 19%;
        z-index: 0;
        transform: rotate(-33deg) scale(-1,1);
    }

    .deco-story-page-5{
        position: absolute;
        width: 197px;
        top: 657px;
        left: 14%;
        z-index: 0;
        transform: rotate(188deg) scale(-1,1);
    }

    .deco-story-page-6{
        position: absolute;
        width: 600px;
        top: 290px;
        left: 55%;
        z-index: 0;
        transform: rotate(85deg);
    }

    .deco-story-page-7{
        position: absolute;
        width: 200px;
        top: -10px;
        left: 39%;
        z-index: 0;
        transform: rotate(63deg);
    }

    .deco-story-page-8{
        position: absolute;
        width: 493px;
        top: 568px;
        left: -3%;
        z-index: 0;
        transform: rotate(172deg);
    }

    .deco-story-page-9{
        position: absolute;
        width: 287px;
        top: 687px;
        left: 50%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-10{
        position: absolute;
        width: 600px;
        top: 579px;
        left: -3%;
        z-index: 0;
    }

    .deco-story-page-11{
        position: absolute;
        width: 227px;
        top: -36px;
        left: 77%;
        z-index: 0;
        transform: rotate(-40deg) scale(-1,1);
    }

    .deco-story-page-12{
        position: absolute;
        width: 404px;
        top: -88px;
        left: 8%;
        z-index: 0;
        transform: rotate(-155deg);
    }

    .deco-story-palm-4 {
        position: absolute;
        width: 40%;
        top: -236px;
        left: 7%;
        z-index: 0;
        transform: rotate(150deg);
    }

    .deco-story-palm-6 {
        left: 75%;
    }

}

@media screen and (min-width: 1280px) and (max-width: 1599px){
    .deco-story-page-1{
        position: absolute;
        width: 650px;
        top: 6px;
        left: -10%;
        z-index: 0;
        transform: rotate(-103deg);
    }

    .deco-story-page-2{
        position: absolute;
        width: 180px;
        top: 55px;
        left: 83%;
        z-index: 0;
        transform: rotate(156deg);
    }

    .deco-story-page-3{
        position: absolute;
        width: 310px;
        top: 521px;
        left: 52%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-4{
        position: absolute;
        width: 425px;
        top: -36px;
        left: 20%;
        z-index: 0;
        transform: rotate(-33deg) scale(-1,1);
    }

    .deco-story-page-5{
        position: absolute;
        width: 250px;
        top: 543px;
        left: 16%;
        z-index: 0;
        transform: rotate(188deg) scale(-1,1);
    }

    .deco-story-page-6{
        position: absolute;
        width: 690px;
        top: 173px;
        left: 56%;
        z-index: 0;
        transform: rotate(85deg);
    }

    .deco-story-page-7{
        position: absolute;
        width: 184px;
        top: -12px;
        left: 40%;
        z-index: 0;
        transform: rotate(63deg);
    }

    .deco-story-page-8{
        position: absolute;
        width: 564px;
        top: 399px;
        left: 0%;
        z-index: 0;
        transform: rotate(172deg);
    }

    .deco-story-page-9{
        position: absolute;
        width: 310px;
        top: 516px;
        left: 52%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-10{
        position: absolute;
        width: 700px;
        top: 447px;
        left: 2%;
        z-index: 0;
    }

    .deco-story-page-11{
        position: absolute;
        width: 258px;
        top: -36px;
        left: 77%;
        z-index: 0;
        transform: rotate(-40deg) scale(-1,1);
    }

    .deco-story-page-12{
        position: absolute;
        width: 426px;
        top: -90px;
        left: 8%;
        z-index: 0;
        transform: rotate(-155deg);
    }
}

@media screen and (min-width: 1600px) and (max-width: 1919px){
    .deco-story-page-1{
        position: absolute;
        width: 650px;
        top: 6px;
        left: -3%;
        z-index: 0;
        transform: rotate(-103deg);
    }

    .deco-story-page-2{
        position: absolute;
        width: 180px;
        top: 65px;
        left: 82%;
        z-index: 0;
        transform: rotate(156deg);
    }

    .deco-story-page-3{
        position: absolute;
        width: 310px;
        top: 561px;
        left: 53%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-4{
        position: absolute;
        width: 425px;
        top: -36px;
        left: 24%;
        z-index: 0;
        transform: rotate(-33deg) scale(-1,1);
    }

    .deco-story-page-5{
        position: absolute;
        width: 250px;
        top: 521px;
        left: 22%;
        z-index: 0;
        transform: rotate(188deg) scale(-1,1);
    }

    .deco-story-page-6{
        position: absolute;
        width: 690px;
        top: 260px;
        left: 59%;
        z-index: 0;
        transform: rotate(85deg);
    }

    .deco-story-page-7{
        position: absolute;
        width: 227px;
        top: -36px;
        left: 44%;
        z-index: 0;
        transform: rotate(63deg);
    }

    .deco-story-page-8{
        position: absolute;
        width: 564px;
        top: 356px;
        left: 4%;
        z-index: 0;
        transform: rotate(172deg);
    }

    .deco-story-page-9{
        position: absolute;
        width: 310px;
        top: 484px;
        left: 53%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-10{
        position: absolute;
        width: 804px;
        top: 389px;
        left: 9%;
        z-index: 0;
    }

    .deco-story-page-11{
        position: absolute;
        width: 300px;
        top: -36px;
        left: 72%;
        z-index: 0;
        transform: rotate(-40deg) scale(-1,1);
    }

    .deco-story-page-12{
        position: absolute;
        width: 450px;
        top: -105px;
        left: 12%;
        z-index: 0;
        transform: rotate(-155deg);
    }
}

@media screen and (min-width: 1920px) and (max-width: 2559px){
    .deco-story-page-1{
        position: absolute;
        width: 650px;
        top: 6px;
        left: 5%;
        z-index: 0;
        transform: rotate(-103deg);
    }

    .deco-story-page-2{
        position: absolute;
        width: 180px;
        top: 77px;
        left: 77%;
        z-index: 0;
        transform: rotate(156deg);
    }

    .deco-story-page-3{
        position: absolute;
        width: 310px;
        top: 580px;
        left: 53%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-4{
        position: absolute;
        width: 425px;
        top: -36px;
        left: 28%;
        z-index: 0;
        transform: rotate(-33deg) scale(-1,1);
    }

    .deco-story-page-5{
        position: absolute;
        width: 250px;
        top: 516px;
        left: 24%;
        z-index: 0;
        transform: rotate(188deg) scale(-1,1);
    }

    .deco-story-page-6{
        position: absolute;
        width: 690px;
        top: 216px;
        left: 59%;
        z-index: 0;
        transform: rotate(85deg);
    }

    .deco-story-page-7{
        position: absolute;
        width: 227px;
        top: -36px;
        left: 44%;
        z-index: 0;
        transform: rotate(63deg);
    }

    .deco-story-page-8{
        position: absolute;
        width: 564px;
        top: 355px;
        left: 13%;
        z-index: 0;
        transform: rotate(172deg);
    }

    .deco-story-page-9{
        position: absolute;
        width: 310px;
        top: 489px;
        left: 53%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-10{
        position: absolute;
        width: 804px;
        top: 388px;
        left: 14%;
        z-index: 0;
    }

    .deco-story-page-11{
        position: absolute;
        width: 300px;
        top: -36px;
        left: 72%;
        z-index: 0;
        transform: rotate(-40deg) scale(-1,1);
    }

    .deco-story-page-12{
        position: absolute;
        width: 450px;
        top: -105px;
        left: 17%;
        z-index: 0;
        transform: rotate(-155deg);
    }
}

@media screen and (min-width: 2560px){
    .deco-story-page-1{
        position: absolute;
        width: 650px;
        top: 6px;
        left: 16%;
        z-index: 0;
        transform: rotate(-103deg);
    }

    .deco-story-page-2{
        position: absolute;
        width: 200px;
        top: 100px;
        left: 70%;
        z-index: 0;
        transform: rotate(156deg);
    }

    .deco-story-page-3{
        position: absolute;
        width: 310px;
        top: 608px;
        left: 52%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-4{
        position: absolute;
        width: 425px;
        top: -36px;
        left: 34%;
        z-index: 0;
        transform: rotate(-33deg) scale(-1,1);
    }

    .deco-story-page-5{
        position: absolute;
        width: 250px;
        top: 521px;
        left: 31%;
        z-index: 0;
        transform: rotate(188deg) scale(-1,1);
    }

    .deco-story-page-6{
        position: absolute;
        width: 690px;
        top: 240px;
        left: 56%;
        z-index: 0;
        transform: rotate(85deg);
    }

    .deco-story-page-7{
        position: absolute;
        width: 227px;
        top: -36px;
        left: 44%;
        z-index: 0;
        transform: rotate(63deg);
    }

    .deco-story-page-8{
        position: absolute;
        width: 587px;
        top: 340px;
        left: 20%;
        z-index: 0;
        transform: rotate(172deg);
    }

    .deco-story-page-9{
        position: absolute;
        width: 310px;
        top: 489px;
        left: 52%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-10{
        position: absolute;
        width: 804px;
        top: 409px;
        left: 24%;
        z-index: 0;
    }

    .deco-story-page-11{
        position: absolute;
        width: 300px;
        top: -36px;
        left: 66%;
        z-index: 0;
        transform: rotate(-40deg) scale(-1,1);
    }

    .deco-story-page-12{
        position: absolute;
        width: 450px;
        top: -105px;
        left: 25%;
        z-index: 0;
        transform: rotate(-155deg);
    }
}

@media screen and (max-width: 480px) {
    .storytelling-info-left:first-child {
        display: none;
    }

    .storytelling-info-right:first-child {
        display: none;
    }

    .storytelling-titled-text {
        flex-basis: 100%;
    }

    .storytelling-info-left {
        padding: 5%;
    }

    .storytelling-info-right {
        padding: 5%;
    }

    .storytelling-info-left .storytelling-img {
        width: 95%;
        height: 444px;
    }

    .storytelling-info-right .storytelling-img {
        width: 95%;
        height: 444px;
    }

    .storytelling-info-left .storytelling-titled-text span {
        font-size: 2.1rem;
        margin: 0 12%;
        line-height: 2rem;
    }

    .storytelling-info-right .storytelling-titled-text span {
        font-size: 2.1rem;
        margin: 0 12%;
        line-height: 2rem;
    }

    .storytelling-info-left .storytelling-titled-text a button {
        font-size: 2rem;
    }

    .storytelling-info-right .storytelling-titled-text a button {
        font-size: 2rem;
    }

    .storytelling-img-container {
        margin: 10% 2% 12% 2%;
    }

    .a-propos-sub-page-title-1,
    .a-propos-sub-page-title-2 {
        font-size: 2rem;
        margin: 5% 26% 0;
    }

    .storytelling-center-textcard .storytelling-titled-text p {
        font-family: "Handlee";
        font-size: 1.2em;
        line-height: 1.6em;
        text-align: justify;
    }

    .deco-story-page-1 {
        position: absolute;
        width: 322px;
        top: 11px;
        left: -7%;
        z-index: 0;
        transform: rotate(-103deg);
    }

    .deco-story-page-2 {
        position: absolute;
        width: 180px;
        top: 461px;
        left: 56%;
        z-index: 0;
        transform: rotate(144deg);
    }

    .deco-story-page-3 {
        position: absolute;
        width: 200px;
        top: 573px;
        left: 29%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-4 {
        position: absolute;
        width: 211px;
        top: 556px;
        left: 28%;
        z-index: 0;
        transform: rotate(-27deg) scale(-1,1);
    }

    .deco-story-page-5 {
        position: absolute;
        width: 120px;
        top: 72px;
        left: 11%;
        z-index: 0;
        transform: rotate(188deg) scale(-1,1);
    }

    .deco-story-page-6 {
        position: absolute;
        width: 290px;
        top: 0px;
        left: 43%;
        z-index: 0;
        transform: rotate(85deg);
    }

    .deco-story-page-7 {
        position: absolute;
        width: 120px;
        top: -18px;
        left: 11%;
        z-index: 0;
        transform: rotate(40deg);
    }

    .deco-story-page-8 {
        position: absolute;
        width: 264px;
        top: 75px;
        left: 52%;
        z-index: 0;
        transform: rotate(-94deg);
    }

    .deco-story-page-9 {
        position: absolute;
        width: 200px;
        top: 581px;
        left: 29%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-10 {
        position: absolute;
        width: 265px;
        top: 38px;
        left: -7%;
        z-index: 0;
        transform: rotate(90deg);
    }

    .deco-story-page-11 {
        position: absolute;
        width: 128px;
        top: 96px;
        left: 63%;
        z-index: 0;
        transform: rotate(160deg);
    }

    .deco-story-page-12 {
        position: absolute;
        width: 202px;
        top: 591px;
        left: 28%;
        z-index: 0;
        transform: rotate(-26deg) scale(-1,1);
    }
}

@media screen and (max-width: 380px) {
    .storytelling-info-left .storytelling-img {
        width: 95%;
        height: 350px;
    }

    .storytelling-info-right .storytelling-img {
        width: 95%;
        height: 350px;
    }

    .storytelling-info-left .storytelling-titled-text a button {
        padding: 5% 30px;
    }

    .storytelling-info-right .storytelling-titled-text a button {
        padding: 5% 30px;
    }

    .deco-story-page-2 {
        position: absolute;
        width: 155px;
        top: 373px;
        left: 61%;
        z-index: 0;
        transform: rotate(144deg);
    }

    .deco-story-page-3 {
        position: absolute;
        width: 166px;
        top: 464px;
        left: 28%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-4 {
        position: absolute;
        width: 166px;
        top: 486px;
        left: 27%;
        z-index: 0;
        transform: rotate(-27deg) scale(-1,1);
    }

    .deco-story-page-5 {
        position: absolute;
        width: 120px;
        top: 0px;
        left: 11%;
        z-index: 0;
        transform: rotate(188deg) scale(-1,1);
    }

    .deco-story-page-6 {
        position: absolute;
        width: 290px;
        top: 0px;
        left: 43%;
        z-index: 0;
        transform: rotate(85deg);
    }

    .deco-story-page-7 {
        position: absolute;
        width: 120px;
        top: -18px;
        left: 11%;
        z-index: 0;
        transform: rotate(40deg);
    }

    .deco-story-page-8 {
        position: absolute;
        width: 264px;
        top: 75px;
        left: 52%;
        z-index: 0;
        transform: rotate(-94deg);
    }

    .deco-story-page-9 {
        position: absolute;
        width: 166px;
        top: 477px;
        left: 28%;
        z-index: 0;
        transform: rotate(-160deg);
    }

    .deco-story-page-10 {
        position: absolute;
        width: 265px;
        top: 38px;
        left: -17%;
        z-index: 0;
        transform: rotate(90deg);
    }

    .deco-story-page-11 {
        position: absolute;
        width: 128px;
        top: 115px;
        left: 63%;
        z-index: 0;
        transform: rotate(160deg);
    }

    .deco-story-page-12 {
        position: absolute;
        width: 166px;
        top: 516px;
        left: 27%;
        z-index: 0;
        transform: rotate(-27deg) scale(-1,1);
    }
}

@media (min-width: 481px) {
    .storytelling-info-left:nth-child(2) {
        display: none;
    }

    .storytelling-info-right:nth-child(2) {
        display: none;
    }
}