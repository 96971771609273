

/*section activity home page*/
@media screen and (min-width: 481px){
    .section-text-activity{
        display: flex;
        flex-direction: column;
    }
    
    .section-text-activity h2{
        padding: 0 100px;
        text-align: center;
        font-family: 'Javanese Text';
        font-size: 2.1rem;
        margin: 15px;
        line-height: 2.2rem;
    }
    
    .section-text-activity h3{
        padding: 0 100px;
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        margin: 15px;
        line-height: 2.2rem;
    }

    .left-activity-section{
        position:relative;
        height:50vh;
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .left-activity-section .img-activity-section{
      left:0px;
      width:100%;
      height:50vh;
      object-fit: cover;
      transition:0.6s ease-in-out;
    }
    
    .left-activity-section .link-activity-section{
        position:absolute;
        bottom:10px;
        left:calc(50% - 37px);
        transition:0.6s ease-in-out;
    }
    
    .button-activity-section{
        background-color: #7DC75A;
        border-radius: 80px;
        width:max-content;
        padding: 10px;
        font-family: 'Handlee';
        text-decoration : underline;
        transition:0.6s ease-in-out;
    }
    
    .left-activity-section .section-text-activity{
        opacity: 0;
        position:absolute;
        right:-50%;
    }
    
    .left-activity-section:hover .img-activity-section{
        position:absolute;
        left:0px;
        width:50%;
        height:50vh;
        object-fit: cover;
        transition:0.6s ease-in-out;
    }
    
    .left-activity-section:hover .section-text-activity{
        display: flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        right:0px;
        width:50%;
        height:50vh;
        transition:0.6s ease-in-out;
        opacity: 1;
    }
    
    .left-activity-section:hover .link-activity-section{
        position:absolute;
        left:calc(75% - 37px);
        transition:0.8s ease-in-out;
        z-index: 2;
    }
    
    
    /*right side*/
    
    .right-activity-section{
      position:relative;
        height:50vh;
        width:100%;
        
        display: flex;
        flex-direction: row;
          align-items: center;
          margin-bottom: 10px;
      }
      .right-activity-section .img-activity-section{
        position:absolute;
        right:0px;
        width:100%;
        height:50vh;
        object-fit: cover;
        transition:0.6s ease-in-out;
    }
    
    .right-activity-section .link-activity-section{
      position:absolute;
      bottom:10px;
      right:calc(50% - 37px);
      transition:0.6s ease-in-out;
      
    }
    
    .right-activity-section .section-text-activity{
        opacity: 0;
        position:absolute;
        left:-50%;
    }
    
    .right-activity-section:hover .img-activity-section{
        position:absolute;
        right:0px;
        width:50%;
        height:50vh;
        object-fit: cover;
        transition:0.6s ease-in-out;
    }
    
    .right-activity-section:hover .section-text-activity{
        display: flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        left:0px;
        width:50%;
        height:50vh;
        transition:0.6s ease-in-out;
        opacity: 1;
    }
    
    .right-activity-section:hover .link-activity-section{
      position:absolute;
      right:calc(75% - 37px);
      transition:0.8s ease-in-out;
      z-index: 2;
    }
}

@media screen and (min-width: 481px) and (max-width: 1024px){
    .section-text-activity h2{
        padding: 0 25px;
        text-align: center;
        font-family: 'Javanese Text';
        font-size: 1.3rem;
        margin: 5px;
        line-height: 1.4rem;
    }

    .section-text-activity h3{
        padding: 0 25px;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        margin: 5px;
        line-height: 1.4rem;
    }
}
    
@media screen and (min-width: 1025px) and (max-width: 1280px){
    .section-text-activity h2{
        padding: 0 50px;
        text-align: center;
        font-family: 'Javanese Text';
        font-size: 1.5rem;
        margin: 5px;
        line-height: 1.6rem;
    }

    .section-text-activity h3{
        padding: 0 50px;
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 5px;
        line-height: 1.6rem;
    }
}
    
@media screen and (min-width: 1281px) and (max-width: 1600px){
    .section-text-activity h2{
        padding: 0 75px;
        text-align: center;
        font-family: 'Javanese Text';
        font-size: 1.7rem;
        margin: 10px;
        line-height: 1.8rem;
    }

    .section-text-activity h3{
        padding: 0 75px;
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        margin: 10px;
        line-height: 1.8rem;
    }
}

.activity1{
    background-color: #FFFDF4;
    font-family:'Amatic SC';
    color:#292026;
}

.activity2{
    background-color: #faf1cf;
    font-family:'Amatic SC';
    color:#292026;
}

.activity3{
    background-color: #52414C;
    font-family:'Amatic SC';
    color:#FFFDF4;
}

/*---------- mobile format activity section ----------------------*/
@media screen and (max-width: 480px){
    .section-text-activity{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        padding: 30px 0;
    }

    .section-text-activity h3{
        display: none;
    }

    .left-activity-section{
        position:relative;
        height:50vh;
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    .right-activity-section{
        position:relative;
        height:50vh;
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

     .img-activity-section{
      left:0px;
      width:100%;
      height:50vh;
      object-fit: cover;
      transition:0.4s ease-in-out;
    }

     .link-activity-section{
        position:absolute;

        bottom: 18.5vh;
        z-index: 3;
        left:calc(50% - 40px);
        transition:0.6s ease-in-out;
    }

    .button-activity-section{
        background-color: #7DC75A;
        border-radius: 80px;
        width:max-content;
        padding: 10px;
        font-family: 'Handlee';
        text-decoration : underline;
        transition:0.4s ease-out;
    }

    .section-text-activity{
        width: 80%;
        position:absolute;
        left:10%;
    }

    .activity1 h2,
    .activity2 h2,
    .activity3 h2 {
        transform: translate(0,-15%);
    }

    .mb-unclicked-activity .link-activity-section {
        left: calc(50% - 35px);
    }

    .mb-unclicked-activity .button-activity-section {
        padding: 5px;
    }


    /*-----affichage du texte----------*/

    .mb-clicked-activity .section-text-activity{
        width: 100%;
        height: 100%;
        left: 0;
        padding: 0;
        border-radius: 0;
    }

    .mb-clicked-activity .section-text-activity h3{
        display: block;
        text-align: center;
    }


    .mb-clicked-activity .link-activity-section{
        position:absolute;
        bottom: 10px;
        z-index: 3;
        left:calc(50% - 40px);
        transition:0.6s ease-in-out;
        transform: translateY(0px);
    }
}
    