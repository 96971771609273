.footer-links {
    display: flex;
    font-family: 'Handlee';
    font-size: 1em;
    line-height: 1.5rem;
    justify-content: space-evenly;
    background-color: #52414C;
}

.footer-links p {
    display: flex;
    flex-direction: column;
}

.footer-links p span {
    color: #DDDDDD;
    text-decoration: none;
    font-size: 1.1em;
}

.footer-links p a {
    color: #BBBBBB;
    text-decoration: none;
}

@font-face {
    font-family: 'Handlee';
    src: url(../Fonts/Handlee-Regular.ttf) format('truetype'), url(../Fonts/Handlee-Regular.woff) format('woff');
}

@media (max-width: 480px) {
    .footer-links {
        font-size: 0.7em;
    }
}