#root {
  overflow-x: hidden;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  
  font-weight: 400;
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

select,
option {
  -webkit-appearance: none;
  color: #292026;
}

body {
  margin: 0%;
  padding: 0px;
  width: 100%;
  height: 100vh;

  background-color: #F4EFDE;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

footer {
  position: relative;
}

@font-face {
  font-family: 'Amatic SC';
  src: url(../Fonts/AmaticSC-Bold.ttf) format('truetype'), url(../Fonts/AmaticSC-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Handlee';
  src: url(../Fonts/Handlee-Regular.ttf) format('truetype'), url(../Fonts/Handlee-Regular.woff) format('woff');
}

.page {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

/*nav bar*/
@media (min-width: 481px) {
  .nav {
    justify-content: space-around;
    height: 90px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 13;
  }

  .nav-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    list-style-type: none;

    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: #292026;
    align-items: center;
    justify-items: center;
  }

  .dropdown-item {
    list-style-type: none;

  }

  .link-nav-bar,
  .link-nav-bar:link,
  .link-nav-bar:visited,
  .link-nav-bar:hover,
  .link-nav-bar:active {
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    display: flex;
    font-family: 'Amatic SC';
    font-size: 2.5vw;
    justify-content: center;
  }

  .dropdown-menu-clicked {
    width: 100%;
    position: relative;
  }

  .dropdown-item {
    align-items: flex-start;
    background-color: #292026;
    display: flex;
    flex-direction: column;
    list-style-position: inside;
    width: 15vw;
    left: 2.5vw;
    padding: 0;
    position: absolute;
  }

  .dropdown-content {
    z-index: 53;
    padding: 5% 0%;
    display: flex;
  }

  @media (orientation: landscape) {
    .logo-nav-bar {
      width: 140px;
    }
  }

  @media (orientation: portrait) {
    .logo-nav-bar {
      width: 140px;
    }
  }

  .navbar-burger,
  .infos-pratiques-navbar {
    display: none;
  }

  .navbar-menu {
    height: 90px;
  }

  .link-nav-bar.mobile-format-accueil {
    display: none;
  }

  .link-nav-bar.desktop-format-accueil {
    display: visible;
  }

}


/*---------------mobile view navbar--------------------*/
@media (max-width: 480px) {

  .navbar-menu.inactive {
    display: none;

  }

  .navbar-menu.active {

    z-index: 90;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }

  .nav-items {

    z-index: 90;
    margin: 0;
    padding: 60px 0px 10px 40px;
    list-style: none;
    position: fixed;


    width: 100vw;
    background-color: #292026;

  }

  .link-nav-bar,
  .link-nav-bar:link,
  .link-nav-bar:visited,
  .link-nav-bar:hover,
  .link-nav-bar:active {
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    display: flex;
    font-family: 'Amatic SC';
    font-size: 1.5em;
  }

  .nav-items li {
    margin-bottom: 10px;

  }

  .dropdown-item {
    list-style-type: none;


  }


  .nav-items li:last-child {
    margin-bottom: 0;
  }



  .nav-items li a {
    color: #F4EFDE;
    text-decoration: none;

    padding: 5px;
    display: block;
  }

  .link-nav-bar.desktop-format-accueil {
    display: none;
  }

  .link-nav-bar.mobile-format-accueil {
    display: visible;
  }

  .navbar-burger {
    z-index: 90;
    width: 100vw;
    height: 60px;

    display: flex;
    font-size: 30px;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: fixed;
    background-color: #292026;
    top: 0;
    left: 0;
    padding: 10px;
    color: #F4EFDE;
  }

  .infos-pratiques-navbar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    color: white;
    font-size: 65%;
    font-family: 'Handlee';
    padding-right: 3%;
    text-align: end;
  }
}

/*------------------------------------------------------*/





/*img home page*/
/*home-screen-section*/
.home-screen-section {
  position: relative;
  width: 100vw;
  top: 0;
  height: clamp(666px, 90vh, 100vh);
  left: 0;
  display: flex;
  align-items: center;
  z-index: -3;

  justify-content: center;
}

.background-image {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: blur(0px);
  top: 0;
  left: 0;
}

.logo-home-screen {
  position: absolute;
  width: 70%;
  top: 10%;
  left: 13.8%;
}

@media (max-width: 480px) {
  .logo-home-screen {
    position: absolute;
    width: 330px;
    top: 10%;
    left: auto;
  }
}

@media (max-width: 340px) {
  .logo-home-screen {
    position: absolute;
    width: 220px;
    top: 10%;
    left: auto;
  }

  .home-screen-section {

    height: clamp(5px, 90vh, 500px);

  }
}
/*-------------------------*/
/*------pastille de prix sur la homepage--------*/

.pastille-prix {
  border: solid #D398BB 0.8vw;
  border-radius: 50%;
  width : 8vw;
  height : 8vw;
  top : 120px;
  right : 30px;
  position: absolute;
  font-size : 1.5vw;
  background-color : #F4EFDE;
  font-family: 'Amatic SC';
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

@media screen and (max-width: 480px){
    .pastille-prix {
        top : 65px;
        right : 5px;
        width : 10vw;
        height : 10vw;
        font-size : 2vw;
    }
}

.pastille-video {
  border: solid #D398BB 0.8vw;
  border-radius: 50%;
  width : 8vw;
  height : 8vw;
  top : 120px;
  left : 30px;
  position: absolute;
  font-size : 1.5vw;
  background-color : #F4EFDE;
  font-family: 'Amatic SC';
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

@media screen and (max-width: 480px){
    .pastille-video {
        top : 65px;
        left : 5px;
        width : 10vw;
        height : 10vw;
        font-size : 2vw;
    }
}
/*----------------------*/
/*-------------------------------------------*/

/*click colect*/
.page .container-click-form .contact-text-lead {
  font-family: 'Handlee';
  font-size: 1.5em;
  color: #F4EFDE;
  line-height: 2.125rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #c6c6c6;
}

.page .container-click-form .contact-text-lead-2 {
  font-family: 'Handlee';
  color: #F4EFDE;
  font-size: 1.5em;
  line-height: 2.125rem;
}

.section-click-collect {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #52414C;
  background-image: url(../images/SVG/Frame_67.svg);
  background-size: 150% 500%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.button-click-collect {
  cursor: pointer;
  height: 60%;
  width: 60%;
  border-radius: 15px;
  border: 1px solid black;
  box-sizing: border-box;
  background-color: #292026;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.text-click-collect {
  color: #faf1cf;
  font-family: 'Amatic SC';
  font-size: 2.5em;
}

@media screen and (max-width: 480px) {
  .text-demande-perso {
    font-size: clamp(2em, 8vw, 3em)
  }

  .button-click-collect {
    width: 80%;
  }

}

.container-click-form {
  width: 100%;
}
/*---------------------------------*/
/*carousel*/







@media screen and (max-width: 549px) {
  

  .decoration-slider1 {
    width: 50%;
  }

  .decoration-slider2 {
    width: 70%;
  }

  .decoration-slider3 {
    width: 70%;
  }

  .decoration-slider4 {
    width: 70%;
  }

  .decoration-slider5 {
    width: 70%;
  }

  .decoration-slider6 {
    width: 70%;
  }
}

@media screen and (min-width: 550px) and (max-width: 1023px) {
  

  .decoration-slider1 {
    width: 40%;
  }

  .decoration-slider2 {
    width: 40%;
  }

  .decoration-slider3 {
    width: 40%;
  }

  .decoration-slider4 {
    width: 40%;
  }

  .decoration-slider5 {
    width: 40%;
  }

  .decoration-slider6 {
    width: 40%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1499px) {


  .decoration-slider1 {
    width: 30%;
  }

  .decoration-slider2 {
    width: 30%;
  }

  .decoration-slider3 {
    width: 30%;
  }

  .decoration-slider4 {
    width: 30%;
  }

  .decoration-slider5 {
    width: 30%;
  }

  .decoration-slider6 {
    width: 30%;
  }
}

@media screen and (min-width: 1500px) {
  .img-card-slider {
    height: 285px;
    width: 250px;
  }

  .card-slider {
    width: 250px;
  }

  .slider-card-evenement {
    height: 700px;
  }

  .decoration-slider1 {
    width: 30%;
  }

  .decoration-slider2 {
    width: 30%;
  }

  .decoration-slider3 {
    width: 30%;
  }

  .decoration-slider4 {
    width: 30%;
  }

  .decoration-slider5 {
    width: 30%;
  }

  .decoration-slider6 {
    width: 30%;
  }
}





.text-card-slider {
  color: rgb(255, 255, 255);
  font-family: 'Handlee';
  margin: 10px 0 20px 0;

}

.slider-card-evenement {
  width: 100vw;
  background-color: #F4EFDE;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-around;
  padding: 16px 0;

}

.carousel-container {
  display: flex;
  position: relative;
  align-items: flex-start;
  height: 100%;
  z-index: 1;
  width: 80vw;
  margin-bottom: 10px;


}



.react-multi-carousel-dot-list {
  width: 80vw;
  position: inherit;
}




.react-multi-carousel-item {
  display: flex;
  justify-content: space-around;
}

.titre-slider-evenement {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Amatic SC';
  z-index: 3;
  padding-top: 20px;
  margin: 0;
  font-weight: 500;
  text-align: center;
}

.sous-titre-slider {
  white-space: pre-line;
  display: flex;
  justify-content: space-evenly;
  font-family: 'Amatic SC';
  z-index: 3;
  font-weight: 500;
  text-align: center;
  background-color: #F4EFDE;
  margin: 10px 0 20px 0;
  width: clamp(200px, 80vw, 80vw);
}

.container-evenement-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: clip;
  overflow-y: hidden;
}

.container-button-evenement {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.container-button-evenement button {
  margin: 0 10px;

  right: 0;
  padding: 10px 10px;
  background-color: rgb(174, 174, 174);
  color: #fff;
  border: solid 2px #545454;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.container-button-evenement button:hover {
  background-color: #545454;
}

.contenu-actu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 80vw;
  padding: 15px;
  position: relative;
  border: #292026 solid 3px;
  border-radius: 20px;
  background-color: #F4EFDE;
  z-index: 3;
  margin: 15px calc(10vw - 15px);
}

.img-actu {
  height: 400px;

}

.button-slider {
  padding: 5px;
  border-radius: 50%;
  margin: 5px;
  border: 0.3em solid;

  background-color: #7DC75A;
  box-shadow: 2px 2px 8px 0px #00000085;


  transition: all 0.15s linear;
}

.button-slider-active {
  background-color: #7DC75A;
  border-color: #7DC75A;


}

.button-slider-inactive {
  background-color: #F4EFDE;
  border-color: #7DC75A;
}

/*decoration slider*/
.decoration-slider1 {
  position: absolute;
  left: -100px;
  top: -20px;
  z-index: 0;
}

.decoration-slider2 {
  position: absolute;
  right: -100px;
  bottom: -200px;
  z-index: 0;
}

.decoration-slider3 {
  position: absolute;
  right: -150px;
  top: -150px;
  z-index: 0;
}

.decoration-slider4 {
  position: absolute;
  right: -200px;
  top: -200px;
  z-index: 0;
}

.decoration-slider5 {
  position: absolute;
  left: -100px;
  bottom: -200px;
  z-index: 0;
}

.decoration-slider6 {
  position: absolute;
  right: -100px;
  bottom: -200px;
  z-index: 0;
}



/* instagram card*/


.instagram-card-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: solid #D398BB 5px;
}

.shadow img {
  position: relative;
  z-index: -1;
  object-fit: cover;

}

.shadow {
  height: 100%;
  border-radius: 10px;
  width: 100%;

  background: linear-gradient(0deg, #D398BB -100%, rgba(85, 85, 85, 0.03) 30%);
}



.instagram-card-img-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.instagram-logo {
  position: absolute;
  top: 10px;
  right: 10px;
}

/*instagram slider*/

.text-slider-instagram {

  font-family: "Amatic SC";
  color: #7DC75A;
}

.link-titre-slider {
  color: inherit;
}

.titre-slider-instagram {
  display: flex;
  justify-content: center;
  margin: 10px 0 5px 0;
  font-size: 2.5rem;
  letter-spacing: 10px;
}

.sous-titre-slider-instagram {
  margin: 0;
  font-weight: 300;
  display: flex;
  justify-content: center;
  font-family: "Handlee";
  color: #D398BB;
}

.slider-card-instagram {


  width: 100vw;
  background-color: #F4EFDE;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;

}

.carousel-container-instagram {
  display: flex;

  align-items: flex-start;


  width: 80vw;

}

.button-slider-instagram {
  padding: 0.4vw;
  border-radius: 50%;
  margin: 5px;
  border: 0.3em solid;

  background-color: #D398BB;
  box-shadow: 2px 2px 8px 0px #00000085;


  transition: all 0.15s linear;
}

.button-slider-active-instagram {
  background-color: #D398BB;
  border-color: #D398BB;


}

.button-slider-inactive-instagram {
  background-color: #F4EFDE;
  border-color: #D398BB;
}

@media screen and (max-width: 480px) {


  .instagram-card {
    width: 300px;
    height: 300px;
  }

  .slider-card-instagram {
    height: 500px;
  }

  .titre-slider-evenement {
    font-size: 2em;
  }

  .sous-titre-slider {
    font-size: 1.1rem;

    margin: 10px 0 0 0;
  }

  .img-actu {
    height: inherit;
    width: inherit;

  }
}

@media screen and (min-width: 481px) and (max-width: 779px) {


  .instagram-card {
    width: 30vw;
    height: 30vw;
  }

  .slider-card-instagram {
    height: 420px;
  }

  .titre-slider {
    font-size: 2em;
  }

  .sous-titre-slider {
    font-size: 1.1rem;


  }

  .img-actu {
    height: 300px;

  }
}


@media screen and (min-width: 780px) and (max-width: 1023px) {

  .instagram-card {
    width: 23vw;
    height: 23vw;
  }

  .slider-card-instagram {
    height: 430px;
  }

  .titre-slider-evenement {
    font-size: 2em;
  }

  .sous-titre-slider {
    font-size: 1.2rem;

  }

  .img-actu {
    height: 300px;

  }
}

@media screen and (min-width: 1024px) and (max-width: 1499px) {


  .instagram-card {
    width: 18vw;
    height: 18vw;
  }

  .slider-card-instagram {
    height: 460px;
  }

  .titre-slider-evenement {
    font-size: 2.5em;
  }

  .sous-titre-slider {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 1500px) {


  .instagram-card {
    width: 250px;
    height: 250px;
  }

  .slider-card-instagram {
    height: 500px;
  }

  .titre-slider-evenement {
    font-size: 2.5em;
  }

  .sous-titre-slider {
    font-size: 1.5rem;
  }
}


/*buttons pages annuaire vegetale*/

.btn {
  border: none;
  background-color: inherit;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}

.btn:hover {
  background: #d3cec0;
}

.btn-pages {
  color: green;
  font-family: "Handlee";
  font-size: 1.5em;
}



/* card annuaire */
.card-annuaire {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 10px 5px 15px #292026;
  height: 551px;
  width: 100%;
  background-color: #52414C;
  transition: transform 330ms ease-in-out;
}

@media screen and (min-width: 481px) {
  .card-annuaire:hover {
    transform: translateY(20px)
  }
}


.img-card-annuaire {
  width: 100%;
  height: 300px;
  border-radius: 15px 15px 0 0;
  object-fit: cover;
}

.nom-card-annuaire {
  font-family: "Handlee";

  font-size: 1.5em;
  margin: 5%;
  color: #F4EFDE;
}

.price-card-annuaire {
  font-family: "Handlee";

  font-size: 1.2em;
  margin-top: 1%;
  margin-left: 5%;
  margin-bottom: 1%;
  margin-right: 5%;
  color: #F4EFDE;
}

.description-card-annuaire {
  font-family: "Handlee";
  font-size: 1.1em;
  text-align: justify;
  color: #F4EFDE;
  margin-top: 4%;
  margin-left: 5%;
  margin-bottom: 4%;
  margin-right: 5%;
}

/*css flyers */
.flyer-background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.flyer {
  display: block;
  margin: 100px auto;
  width: clamp(250px, 90vw, 1100px);
}


/*list card annauire */

.annuaire-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  z-index: 3;
  margin-bottom: 40px;
}

.list-card-annuaire {
  padding: 0;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 3;
  background-color: #F4EFDE;
  gap: 30px 0px;
}

@media screen and (max-width: 480px) {
  .list-card-annuaire {
    background-color: transparent;
  }
}

.filter-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: solid rgb(0, 0, 0);
  border-radius: 15px;
  border-width: 2px;
  border-color: #52414C;
  width: 70vw;
  height: 5vw;
  z-index: 3;
}





/*filter bar*/



.select-button-filter {
  border: none;
  outline: none;
  appearance: none;
  background-color: #F4EFDE;
  background-image: url('../images/icon__arrow_down_.svg');
  background-size: 40%;
  background-position: right -20px top 50%;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  text-indent: 0px;
  font-family: 'Handlee';
  font-size: 1.4em;
  color: #52414C;
  height: 100%;
  z-index: 2;

}


.button-filter-div {
  gap: 0 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 30%;
  height: 100%;
  align-items: center;
}

.filter-bar-form {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
}

.logo-filter-bar {
  height: 50px;
}

.option-button-filter {
  color: #52414C;
}


.button-submit {
  background-color: #7DC75A;
  border-radius: 80px;
  width: 10%;
  height: 80%;
  font-size: 1.2em;
  font-family: 'Handlee';
  color: #F4EFDE;
  border: none;
}




/*textfield*/
.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 30%;
  min-width: 20%;
  height: 80%;
}

.input-search-bar {
  width: 150%;
  height: 100%;
  line-height: 28px;
  padding: 0 1rem 0 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #52414C;
  color: #7DC75A;
  transition: .3s ease;
  font-family: "Handlee";
  font-size: 1.1em;
}

.input-search-bar::placeholder {
  color: #bcbcc6;
}

.input-search-bar:focus,
.input-search-bar:hover {
  outline: none;
  border-color: #7DC75A;
  background-color: #372c33;

  box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #7DC75A;
  width: 1rem;
  height: 1rem;
}

/* for webkit-based browsers */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;

  background-color: transparent;
  background-image: url('../images/croix.svg');
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
}

/* for Microsoft Edge and Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  /* hide the default clear button */
  width: 20px;
  height: 20px;

  background-color: transparent;
  background-image: url('../images/croix.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.vertical-line {

  width: 10px;
}

@media screen and (max-width: 463px) {
  .card-li {
    width: clamp(300px, 100%, 450px);
    padding-bottom: 2%;
  }

  .decoration-annuaire1 {
    width: 40%;
  }

  .decoration-annuaire2 {
    width: 50%;
  }

  .decoration-annuaire3 {
    width: 50%;
  }

  .decoration-annuaire4 {
    width: 50%;
  }

  .decoration-annuaire5 {
    width: 50%;
  }

  .filter-bar {
    width: 95vw;
    height: 10vw;
    font-size: 0.6em;
  }

  .button-filter-div {
    width: 20%;
  }

  .select-button-filter {
    background-position: right -50% top 50%;
    background-size: 60%;
    font-size: 1.4em;
  }

  .logo-filter-bar {
    display: none;
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;

    background-color: transparent;
    background-image: url('../images/croix.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .group {
    width: 40%;
    max-width: 35%;
  }

}

@media screen and (min-width: 464px) and (max-width: 1023px) {
  .card-li {
    width: 40%;
    padding-bottom: 1%;
  }

  .decoration-annuaire1 {
    width: 40%;
  }

  .decoration-annuaire2 {
    width: 50%;
  }

  .decoration-annuaire3 {
    width: 50%;
  }

  .decoration-annuaire4 {
    width: 50%;
  }

  .decoration-annuaire5 {
    width: 50%;
  }



  .filter-bar {

    width: 90vw;
    height: 7vw;

  }

  .button-filter-div {
    width: 20%;
  }

  .select-button-filter {
    background-position: right -50% top 50%;
    background-size: 60%;
    font-size: 1em;
  }

  .logo-filter-bar {
    display: none;
  }

}

@media screen and (min-width: 1024px)and (max-width: 1499px) {
  .card-li {
    width: 30%;
    padding-bottom: 1%;
  }

  .decoration-annuaire1 {
    width: 40%;
  }

  .decoration-annuaire2 {
    width: 40%;
  }

  .decoration-annuaire3 {
    width: 40%;
  }

  .decoration-annuaire4 {
    width: 40%;
  }

  .decoration-annuaire5 {
    width: 40%;
  }

}

@media screen and (min-width: 1500px) {
  .card-li {
    width: 23.01%;
    padding-bottom: 2%;
  }

  .decoration-annuaire1 {
    width: 30%;
  }

  .decoration-annuaire2 {
    width: 40%;
  }

  .decoration-annuaire3 {
    width: 30%;
  }

  .decoration-annuaire4 {
    width: 30%;
  }

  .decoration-annuaire5 {
    width: 30%;
  }


}



/*page annuaire végétal*/

.annuaire-vegetal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  background-size: cover;
  background-image: url("../images/SVG/Group_2.svg");
  margin-top: 2%;
}

/*decoration annuaire végétal*/

.decoration-annuaire1 {
  position: absolute;
  left: -100px;

  z-index: 0;
}

.decoration-annuaire2 {
  position: absolute;
  left: -200px;
  top: 10%;
  transform: rotate(120deg);
  z-index: 0;
}

.decoration-annuaire3 {
  position: absolute;
  right: -100px;
  top: 20%;
  z-index: 0;
}

.decoration-annuaire4 {
  position: absolute;
  right: -200px;
  top: 50%;
  transform: scaleX(-1) rotate(180deg);
  z-index: 0;
}

.decoration-annuaire5 {
  position: absolute;
  left: -200px;
  top: 60%;
  z-index: 0;
  transform: rotate(-40deg);
}



/* section map */

.section-map {
  width: 100%;
  height: 60vw;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  position: relative;
  background-color: #F4EFDE;
}

.map {
  width: 50vw;
  height: 40vw;
  max-height: 600px;
  border-style: solid;
  border-width: 10px;
  border-color: #52414C;
  border-radius: 5px;
  margin-bottom: 40px;
}

.titre-map {
  font-family: 'Handlee';
  color: #7DC75A;
  font-size: 2.5em;
  margin: 10px 0;
}

@media screen and (max-width: 780px) and (orientation: portrait) {
  .section-map {
    width: 100%;
    height: 50vw;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    position: relative;
    background-color: #F4EFDE;
  }

  .map {
    width: 80vw;
    height: 350px;
    max-height: 600px;
    border-style: solid;
    border-width: 10px;
    border-color: #52414C;
    border-radius: 5px;
    margin-bottom: 40px;
  }

  .titre-map {
    font-size: 2em;
  }
}

@media screen and (max-width: 780px) and (orientation: landscape) {
  .section-map {
    width: 100%;
    height: 80vw;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    position: relative;
    background-color: #F4EFDE;
  }

  .map {
    width: 80vw;
    height: 80vh;
    max-height: 600px;
    border-style: solid;
    border-width: 10px;
    border-color: #52414C;
    border-radius: 5px;
    margin-bottom: 40px;
  }
}

.container-map .button-map {
  font-size: 1.4rem;
  width: 200px;
  background-color: #7DC75A;
  height: 50px;
  border-radius: 10px;
  font-family: 'Handlee';
  color: #52414C;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .container-map .button-map {
    font-size: 1rem;
    width: 100px;
  }

  .container-map .button-map-clicked {
    font-size: 1rem;
    width: 100px;
  }
}

.container-map .button-map-clicked {
  font-size: 1.4rem;
  width: 200px;
  background-color: #D398BB;
  height: 50px;
  border-radius: 10px;
  font-family: 'Handlee';
  color: #52414C;

}

.container-map {
  display: flex;
  gap: 0 30px;
  margin-bottom: 40px;
}

/*------mobile map --------------*/
@media screen and (max-width: 480px) and (orientation: portrait) {
  .section-map {
    width: 100%;
    height: 50vw;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    position: relative;
    background-color: #F4EFDE;
  }

  .map {
    width: 80vw;
    height: 350px;
    max-height: 600px;
    border-style: solid;
    border-width: 10px;
    border-color: #52414C;
    border-radius: 5px;
    margin-bottom: 40px;
  }

  .titre-map {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 480px) and (orientation: landscape) {
  .section-map {
    width: 100%;
    height: 50vw;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    position: relative;
    background-color: #F4EFDE;
  }

  .map {
    width: 80vw;
    height: 350px;
    max-height: 600px;
    border-style: solid;
    border-width: 10px;
    border-color: #52414C;
    border-radius: 5px;
    margin-bottom: 40px;
  }

}

/*----------------------------------------------------*/



@media screen and (max-width: 1023px) {

  .decoration-map1 {
    display: none;
  }

  .decoration-map2 {
    display: none;
  }

  .decoration-map3 {
    display: none;
  }

  .decoration-map4 {
    display: none;
  }

  .decoration-map5 {
    display: none;
  }

  .decoration-map6 {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1499px) {

  .decoration-map1 {
    width: 30%;
  }

  .decoration-map2 {
    width: 30%;
  }

  .decoration-map3 {
    width: 30%;
  }

  .decoration-map4 {
    width: 30%;
  }

  .decoration-map5 {
    width: 30%;
  }

  .decoration-map6 {
    width: 30%;
  }
}

@media screen and (min-width: 1500px) {

  .decoration-map1 {
    width: 30%;
  }

  .decoration-map2 {
    width: 30%;
  }

  .decoration-map3 {
    width: 30%;
  }

  .decoration-map4 {
    width: 30%;
  }

  .decoration-map5 {
    width: 30%;
  }

  .decoration-map6 {
    width: 30%;
  }
}

/*decoration map*/
.decoration-map1 {
  position: absolute;
  left: -150px;
  top: -20px;
  z-index: 0;
}

.decoration-map2 {
  position: absolute;
  right: -100px;
  bottom: -200px;
  z-index: 0;
}

.decoration-map3 {
  position: absolute;
  right: -150px;
  top: -150px;
  z-index: 0;
}

.decoration-map4 {
  position: absolute;
  right: -200px;
  top: -200px;
  z-index: 0;
}

.decoration-map5 {
  position: absolute;
  left: -100px;
  bottom: -200px;
  z-index: 0;
}

.decoration-map6 {
  position: absolute;
  right: -100px;
  bottom: -200px;
  z-index: 0;
}

/*map css*/

.map-container {
  width: 50%;
  width: 50vh;
  z-index: 50;
}



/*services*/
.page-abonnement .section-card-service {
  background-color: #F4EFDE;
}

.page-abonnement .section-card-service {
  color: #52414C;
}

.page-abonnement .section-offres-moments {
  background-color: #52414C;
}

.page-abonnement .titre-offres {
  color: #F4EFDE;
}

.page-abonnement .offres-card {
  background-color: #F4EFDE;
  width: 400px;
  height: 400px;

}

.page-abonnement .nom-offres-card {
  color: #52414C;
}

.page-abonnement .section-click-collect {
  background-color: #F4EFDE;
}

.page-abonnement .card-selection {
  border-color: #F4EFDE;

}






.page-pret .section-card-service {
  background-color: #F4EFDE;
}

.page-pret .section-card-service {
  color: #52414C;
}

.page-pret .section-offres-moments {
  background-color: #52414C;
}

.page-pret .titre-offres {
  color: #F4EFDE;
}

.page-pret .offres-card {
  background-color: #F4EFDE;
  width: 400px;
  height: 400px;
}

.page-pret .nom-offres-card {
  color: #52414C;
}

.page-pret .section-click-collect {
  background-color: #F4EFDE;
}

.page-pret .card-selection {
  border-color: #F4EFDE;
}

.page-tombe .section-card-service {
  background-color: #52414C;
}

.page-tombe .section-card-service {
  color: #F4EFDE;
}

.page-tombe .titre-offres {
  color: #292026;
}

.page-tombe .contact-center-element {
  color: #F4EFDE;
}

.page-tombe .card-selection {
  border-color: #52414c;
}

.page-tombe {
  background-image: url("../images/SVG/Component_5.svg");
}


/*selection du moment*/
.section-card-service {
  display: flex;
  position: relative;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  background-color: #52414C;
  background-size: 100vw;
  background-image: url("../images/SVG/Component_5.svg");
  background-position: center;
}


/*-----------------------------------*/




.titre-collection {
  font-family: 'Amatic SC';
  font-size: 3.2em;
}

.sous-titre-collection {
  text-align: center;
  font-family: 'Handlee';
  font-size: 1.5em;
}

.sous-titre-collection-2 {
  text-align: center;
  font-family: 'Handlee';
  font-size: 1.3em;
  white-space: pre-line;
}

@media screen and (max-width: 480px) {
  .titre-collection {
    font-family: 'Amatic SC';
    font-size: 2.2em;
  }

  .sous-titre-collection {
    font-family: 'Handlee';
    font-size: 1em;
  }
}

@media screen and (max-width: 480px) {
  .titre-collection {
    font-family: 'Amatic SC';
    font-size: 2.2em;
  }

  .sous-titre-collection {
    font-family: 'Handlee';
    font-size: 1em;
  }
}

.card-selection-section {
  flex-grow: 1;
  flex-shrink: 0;

  gap: 10px;
  margin: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2%;
  width: clamp(10%, 90%, 1300px);
}

.centerDiv{
  display: flex;
  justify-content: center;
}

.card-selection {
  width: 300px;
  height: 370px;
  border-radius: 20px 20px 10px 10px;
  border: 5px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7DC75A;
}

.img-card-service {
  width: 100%;
  border-radius: 10px 10px 0 0;
  height: 65%;
  object-fit: cover;
}

.nom-card-service {
  white-space: pre-line;
}

.text-service-card {
  width: 80%;
  height: 35%;
  color: #292026;
  font-family: "handlee";
  font-size: 1.15em;
}

@media screen and (max-width: 780px) {


  .section-offres-moments .embla {
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100vw;
    margin: 0 0 20px 0;

  }

  .section-offres-moments .embla__container {
    display: flex;
    width: 400px;
    margin: 0 calc(50vw - 150px);
  }

  .section-card-service .embla {
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100vw;
    margin: 0 0 20px 0;

  }

  .section-card-service .embla__container {
    display: flex;
    width: 300px;
    margin: 0 calc(50vw - 150px);
  }

  .card-selection {
    flex: 0 0 90%;
    margin: 0;
    min-width: 0;
  }

  .sous-titre-collection {
    font-size: 1.2em;
  }

  .titre-collection {
    font-size: 2em;
  }

  .sous-titre-collection-2 {
    font-size: 1em;
  }
}

button {
  border: none;
}

/*offres du moment*/

.section-offres-moments {
  display: flex;
  padding-bottom: 2%;
  flex-direction: column;
  align-items: center;
}

.titre-offres {
  font-family: 'Amatic SC';
  padding-bottom: 2%;
  font-size: 2.5em;
  color: #FFFDF4;
}

.section-offres-card {
  display: flex;
  width: 90vw;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 2%;
  justify-content: space-around;
  gap: 20px 0;
}

.offres-card {

  margin: 5px;
  border-radius: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #52414C;
}


@media screen and (max-width: 780px) {
  .offres-card {
    flex: 0 0 100%;
    margin: 0 2%;
    min-width: 0;
  }
}

.img-offres-card {
  width: 100%;
  object-fit: cover;
  height: 315px;
  border-radius: 15px 15px 0 0;
}

.nom-offres-card {
  padding: 4%;
  font-family: 'Handlee';
  font-size: 1.3em;
  color: #FFFDF4;
}

/*Abonnements basiques*/

.section-abonnements-basiques {
  display: flex;
  padding-bottom: 2%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #faf1cf;
}

.container-abonnements-basiques {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 2%;
  justify-content: space-around;
}

.titre-abonnements-basiques {
  font-family: 'Amatic SC';
  padding-bottom: 2%;
  font-size: 2.5em;
  color: #52414C;
}

.titre-abonnements-basiques-2 {
  font-family: 'Amatic SC';
  padding: 0 25%;
  font-size: 2em;
  color: #52414C;
}

@media screen and (max-width: 780px) {
  .titre-abonnements-basiques-2 {
    font-family: 'Amatic SC';
    padding: 0 5%;
    font-size: 1.5em;
    color: #52414C;
  }
}

.abonnements-card {
  flex-basis: calc(25% + 15px);
  margin: 5px;
  background-color: transparent;
  perspective: 1000px;
}

.abonnements-card h3,
.abonnements-card p {
  font-family: 'Amatic SC';
  color: #FFFDF4;
  margin: 0 30px;
  font-size: 2rem;
  margin-bottom: 4%;
}

.abonnements-card span {
  font-family: 'Amatic SC';
  color: #7DC75A;
  margin: 0;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 4%;
}

.abonnements-card h2 {
  font-family: 'Amatic SC';
  color: #D398BB;
  margin: 0;
  font-size: 2.2rem;
}

.img-abonnements-card {
  width: 100%;
  border-radius: 15px;
}

.flip-card {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.abonnements-card:hover .flip-card {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

.backflip-svg-holder {
  position: relative;
}

.deco-backflip {
  position: absolute;
  width: 200px;
  left: -340px;
  top: -135px;
  z-index: 0;
}

/* Style the front side (fallback if image is missing) */


/* Style the back side */
.flip-card-back {
  position: absolute;
  border-radius: 15px;
  background-color: #52414C;
  transform: rotateY(180deg);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 270px) {
  .container-abonnements-basiques {
    flex-direction: column;
  }

  .abonnements-card {
    margin: 5% 15%;
  }

  .abonnements-card h3 {
    font-size: 0.7rem;
    margin: 0;
  }

  .abonnements-card p {
    font-size: 0.7rem;
    margin: 0;
  }

  .abonnements-card span {
    font-size: 0.7rem;
  }

  .abonnements-card h2 {
    font-size: 0.9rem;
  }

  .deco-backflip {
    width: 70px;
    left: -120px;
    top: -50px;
  }
}

@media screen and (min-width: 271px) and (max-width: 340px) {
  .container-abonnements-basiques {
    flex-direction: column;
  }

  .abonnements-card {
    margin: 5% 15%;
  }

  .abonnements-card h3 {
    font-size: 0.9rem;
    margin: 0;
  }

  .abonnements-card p {
    font-size: 0.9rem;
    margin: 0;
  }

  .abonnements-card span {
    font-size: 0.9rem;
  }

  .abonnements-card h2 {
    font-size: 1.1rem;
  }

  .deco-backflip {
    width: 80px;
    left: -140px;
    top: -50px;
  }
}

@media screen and (min-width: 341px) and (max-width: 420px) {
  .container-abonnements-basiques {
    flex-direction: column;
  }

  .abonnements-card {
    margin: 5% 15%;
  }

  .abonnements-card h3 {
    font-size: 1rem;
  }

  .abonnements-card p {
    font-size: 1rem;
  }

  .abonnements-card span {
    font-size: 1rem;
  }

  .abonnements-card h2 {
    font-size: 1.2rem;
  }

  .deco-backflip {
    width: 90px;
    left: -160px;
    top: -60px;
  }
}

@media screen and (min-width: 421px) and (max-width: 480px) {
  .container-abonnements-basiques {
    flex-direction: column;
  }

  .abonnements-card {
    margin: 5% 15%;
  }

  .abonnements-card h3 {
    font-size: 1.3rem;
  }

  .abonnements-card p {
    font-size: 1.3rem;
  }

  .abonnements-card span {
    font-size: 1.3rem;
  }

  .abonnements-card h2 {
    font-size: 1.5rem;
  }

  .deco-backflip {
    width: 130px;
    left: -220px;
    top: -110px;
  }
}

@media screen and (min-width: 481px) and (max-width: 700px) {
  .container-abonnements-basiques {
    flex-direction: column;
  }

  .abonnements-card {
    margin: 5% 15%;
  }

  .abonnements-card h3 {
    font-size: 1.6rem;
  }

  .abonnements-card p {
    font-size: 1.6rem;
  }

  .abonnements-card span {
    font-size: 1.6rem;
  }

  .abonnements-card h2 {
    font-size: 1.8rem;
  }

  .deco-backflip {
    width: 170px;
    left: -300px;
    top: -100px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1000px) {
  .container-abonnements-basiques {
    flex-direction: column;
  }

  .abonnements-card {
    margin: 5% 15%;
  }

  .abonnements-card h3 {
    font-size: 2rem;
  }

  .abonnements-card p {
    font-size: 2rem;
  }

  .abonnements-card span {
    font-size: 2rem;
  }

  .abonnements-card h2 {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1300px) {
  .abonnements-card h3 {
    font-size: 1.1rem;
  }

  .abonnements-card p {
    font-size: 1.1rem;
  }

  .abonnements-card span {
    font-size: 1.1rem;
  }

  .abonnements-card h2 {
    font-size: 1.3rem;
  }

  .deco-backflip {
    width: 130px;
    left: -200px;
    top: -90px;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1600px) {
  .abonnements-card h3 {
    font-size: 1.5rem;
  }

  .abonnements-card p {
    font-size: 1.5rem;
  }

  .abonnements-card span {
    font-size: 1.5rem;
  }

  .abonnements-card h2 {
    font-size: 1.7rem;
  }

  .deco-backflip {
    width: 150px;
    left: -260px;
    top: -120px;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1920px) {
  .abonnements-card h3 {
    font-size: 1.8rem;
  }

  .abonnements-card p {
    font-size: 1.8rem;
  }

  .abonnements-card span {
    font-size: 1.8rem;
  }

  .abonnements-card h2 {
    font-size: 2rem;
  }
}




/*tombe paysagère*/
.frontground-tombe-right {
  position: absolute;
  top: -20%;
  height: 140%;
  right: 0;
  z-index: 10;
}

.frontground-tombe-left {
  position: absolute;
  top: -15%;
  height: 138%;
  left: -10%;
  width: 40%;
  z-index: 10;
}

/*------click and collect form--------*/

.basket-container {
  height: 330px;
  /* Set the desired height of the container */
  overflow-y: auto;
  color: #FFFDF4;
  /* Add vertical scrollbar if necessary */
  border: solid #F4EFDE;
  border-radius: 10px;
  margin: 30px 0;
  padding: 5px;
  color: #F4EFDE;
}

.basket-item {
  width: 80%;

}



.container-product {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.container-quantity {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0;
  flex-direction: column;

}

.container-quantity .contact-box-component {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}

.container-interact-validate {
  display: flex;
  width: 100%;
}


.button_plus {
  border-radius: 15px;
  color: #F4EFDE;
  width: 35px;
  height: 35px;
  background: #372c33;
  cursor: pointer;
  border: 2px solid #7DC75A;
  font-size: 1.2em;
}





.button_plus:hover {
  background-color: #095776;
  transition: 0.2s;
}


.validate-button {
  background-color: #F4EFDE;
  height: 2.5rem;
  width: 5rem;
  border-radius: 10px;
  margin: 0 20px;
  border: solid #7DC75A;
}

@media screen and (min-width: 481px) {
  .input-number {
    margin: 0px 10px;
    width: 35%;
  }
}

@media screen and (max-width: 480px) {
  .input-number {
    margin: 5px 5px;
    width: 30px;
  }

  .validate-button {
    margin: 0 10px;
  }

}


.label-date {
  color: #FFFDF4;
  font-family: 'Handlee';
  display: flex;
  flex-direction: column;
  margin: 0 0 45px 0;
}

.label-date input {
  font-size: 1.3rem;
  padding: 10px;
  height: 30px;
  margin: 10px 0;
  width: 60%;
  border-radius: 10px;
  border: none;
}

.collect-box-component {
  display: flex;
  flex-direction: column;
  margin: 0 0 25px;
}

.collect-form-background {
  padding: 5% 10%;
  background-color: #292026;
  position: relative;
  border-radius: 50px;
  z-index: 2;

}

.collect-center-element {
  margin: 0 auto;
  width: 85%;
  max-width: 1200px;
  padding: 0 1.25rem;
}


/*------page de mentions légales--------*/

.mentionsdiv {
  padding: 2%;
}