@media (min-width: 481px) {
    .infos-pratiques-ul-phone{
        display: none;
    }

    .infos-pratiques {
        background-color: #FFFDF4;
        padding-top: 20px;
        font-family: "Handlee";
    }

    .infos-pratiques-title {
        padding-left: 10%;
        font-size: 1.5em;
    }

    .infos-pratiques-ul-computer {
        margin: 0;
        display: flex;
        justify-content: space-evenly;
        list-style-type: none;
    }

    .infos-pratiques ul li p {
        display: flex;
        flex-direction: column;
    }

    .infos-pratiques ul li p span:first-of-type {
        font-size: 1.1em;
    }

    .infos-pratiques-button {
        color: white;
        margin-top: 15px;
        padding: 15px 40px;
        background-color: black;
    }

    .scrollTop-contact {
        color: white;
        padding: 12px 20px;
        background-color: #7DC75A;
        font-size: 1.5em;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
    }
}

@media (max-width: 480px) {
    .infos-pratiques-ul-computer{
        display: none;
    }

    .scrollTop-contact {
        color: white;
        padding: 8px 13.5px;
        background-color: #7DC75A;
        font-size: 1.2em;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
    }

    .infos-pratiques {
        background-color: #FFFDF4;
        padding-top: 30px;
        font-family: "Handlee";
        display: flex;
        flex-direction: column;
    }

    .infos-pratiques p:first-child {
        margin: 0;
        display: flex;
        justify-content: center;
    }

    .infos-pratiques-title {
        padding: 0;
        font-size: 1.2em;
    }

    .infos-pratiques-ul-phone ul {
        margin: 0;
        display: flex;
        justify-content: space-evenly;
        list-style-type: none;
        padding: 10px 0 0 0;
    }

    .infos-pratiques-ul-phone > p span {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.8em;
    }

    .infos-pratiques-ul-phone > p span:nth-child(2) {
        margin: 5px 0;
        font-size: 1.3em;
    }

    .infos-pratiques ul li p {
        display: flex;
        flex-direction: column;
    }

    .infos-pratiques ul li p span {
        font-size: 0.7em;
    }

    .infos-pratiques ul li p span:first-of-type {
        font-size: 0.8em;
    }

    .infos-pratiques-button {
        color: white;
        margin-top: 15px;
        padding: 15px 40px;
        background-color: black;
    }
}