.contact-section{
    position: relative;
   overflow-y: hidden;
   overflow-x: hidden;
}

.contact-story-page-1{
    position: absolute;
   
}

.contact-story-page-2{
    position: absolute;
  
}

.contact-story-page-3{
    position: absolute;

}

.contact-story-page-4{
    position: absolute;
    
}

.contact-story-page-5{
    position: absolute;
    
}

.contact-story-page-6{
    position: absolute;
    
}

.contact-form-background {
    z-index: 2;
    border: solid #F4EFDE 10px;
}

/*Storytelling page*/
@media screen and (max-width: 1023px) {
    .contact-story-page-1{
        position: absolute;
        width: 330px;
        top: 220px;
        left: 2%;
        transform: rotate(-172deg);
    }

    .contact-story-page-2{
        position: absolute;
        width: 400px;
        top: 210px;
        right: -98px;
        transform: rotate(211deg);
    }

    .contact-story-page-3{
        position: absolute;
        width: 800px;
        top: 339px;
        left: -277px;
        z-index: 1;
        transform: rotate(-62deg);
    }

    .contact-story-page-4{
        position: absolute;
        width: 600px;
        top: 666px;
        left: 71%;
        z-index: -1;
        transform: rotate(24deg);
    }

    .contact-story-page-5{
        position: absolute;
        width: 340px;
        top: 1360px;
        left: -16%;
        z-index: 3;
    }

    .contact-story-page-6{
        position: absolute;
        width: 600px;
        top: 1510px;
        left: 66%;
        z-index: 6;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px){
    .contact-story-page-1{
        position: absolute;
        width: 330px;
        top: 220px;
        left: 2%;
        transform: rotate(-172deg);
    }

    .contact-story-page-2{
        position: absolute;
        width: 400px;
        top: 210px;
        right: -98px;
        transform: rotate(211deg);
    }

    .contact-story-page-3{
        position: absolute;
        width: 800px;
        top: 339px;
        left: -277px;
        z-index: 1;
        transform: rotate(-62deg);
    }

    .contact-story-page-4{
        position: absolute;
        width: 600px;
        top: 666px;
        left: 71%;
        z-index: -1;
        transform: rotate(24deg);
    }

    .contact-story-page-5{
        position: absolute;
        width: 400px;
        top: 1052px;
        left: -16%;
        z-index: 3;
    }

    .contact-story-page-6{
        position: absolute;
        width: 600px;
        top: 1206px;
        left: 66%;
        z-index: 6;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1599px){
    .contact-story-page-1{
        position: absolute;
        width: 331px;
        top: 249px;
        left: 2%;
        transform: rotate(-172deg);
    }

    .contact-story-page-2{
        position: absolute;
        width: 400px;
        top: 210px;
        right: -98px;
        transform: rotate(211deg);
    }

    .contact-story-page-3{
        position: absolute;
        width: 800px;
        top: 445px;
        left: -277px;
        z-index: 1;
        transform: rotate(-62deg);
    }

    .contact-story-page-4{
        position: absolute;
        width: 600px;
        top: 666px;
        left: 71%;
        z-index: -1;
        transform: rotate(24deg);
    }

    .contact-story-page-5{
        position: absolute;
        width: 400px;
        top: 1052px;
        left: -16%;
        z-index: 3;
    }

    .contact-story-page-6{
        position: absolute;
        width: 800px;
        top: 1206px;
        left: 56%;
        z-index: 6;
        pointer-events: none;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1919px){
    .contact-story-page-1{
        position: absolute;
        width: 429px;
        top: 203px;
        left: 99px;
        transform: rotate(-170deg);
    }

    .contact-story-page-2{
        position: absolute;
        width: 28vw;
        top: 160px;
        right: -73px;
        transform: rotate(211deg);
    }

    .contact-story-page-3{
        position: absolute;
        width: 866px;
        top: 402px;
        left: -327px;
        z-index: 1;
        transform: rotate(-62deg);
    }

    .contact-story-page-4{
        position: absolute;
        width: 913px;
        top: 506px;
        right: -21%;
        z-index: -1;
        transform: rotate(24deg);
    }

    .contact-story-page-5{
        position: absolute;
        width: 684px;
        top: 913px;
        left: -12%;
        z-index: 3;
    }

    .contact-story-page-6{
        position: absolute;
        width: 1090px;
        top: 1050px;
        left: 56%;
        z-index: 6;
    }
}

@media screen and (min-width: 1920px) and (max-width: 2559px){
    .contact-story-page-1{
        position: absolute;
        width: 541px;
        top: 203px;
        left: 99px;
        transform: rotate(-170deg);
    }

    .contact-story-page-2{
        position: absolute;
        width: 34vw;
        top: -22px;
        right: -45px;
        transform: rotate(184deg);
    }

    .contact-story-page-3{
        position: absolute;
        width: 1128px;
        top: 454px;
        left: -271px;
        left: -483px;
        z-index: 1;
        transform: rotate(-62deg);
    }

    .contact-story-page-4{
        position: absolute;
        width: 1008px;
        top: 605px;
        right: -18%;
        z-index: -1;
        transform: rotate(24deg);
    }

    .contact-story-page-5{
        position: absolute;
        width: 724px;
        top: 931px;
        left: -4%;
        transform: rotate(342deg);
        z-index: 3;
    }

    .contact-story-page-6{
        position: absolute;
        width: 1090px;
        top: 1050px;
        left: 56%;
        z-index: 6;
    }
}

@media screen and (min-width: 2560px){
    .contact-story-page-1{
        position: absolute;
        width: 657px;
        top: 38px;
        left: 99px;
        transform: rotate(-170deg);
    }

    .contact-story-page-2{
        position: absolute;
        width: 28vw;
        top: -81px;
        right: -73px;
        transform: rotate(211deg);
    }

    .contact-story-page-3{
        position: absolute;
        width: 1297px;
        top: 276px;
        left: -271px;
        z-index: 1;
        transform: rotate(-42deg);
    }

    .contact-story-page-4{
        position: absolute;
        width: 1177px;
        top: 385px;
        right: -8%;
        z-index: -1;
        transform: rotate(-107deg);
    }

    .contact-story-page-5{
        position: absolute;
        width: 888px;
        top: 979px;
        left: 2%;
        transform: rotate(320deg);
        z-index: 3;
    }

    .contact-story-page-6{
        position: absolute;
        width: 1090px;
        top: 1050px;
        left: 56%;
        z-index: 6;
    }
}

@media (max-width: 480px) {
    .contact-story-page-5{
        position: absolute;
        width: 200px;
        top: 1542px;
        left: -16%;
        z-index: 3;
    }
}