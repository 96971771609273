.container-path {
  margin: 0;
}

.list-path {
    padding-left : 5%;
    margin: 0 0 0 0;
    display: flex;
    list-style-type: none;
    border-bottom: 1px solid #e6e6e6;
    background-color: #FFFDF4;
    position: relative;
    z-index:12;
}

.list-path li p span a {
  color: black;
  text-decoration: none;
  margin: 0;
  font-family: 'Handlee';
}

.list-path li i {
  margin: 0 7px 0 5px;
}

@font-face {
  font-family: 'Amatic SC';
  src: url(../Fonts/AmaticSC-Bold.ttf) format('truetype'), url(../Fonts/AmaticSC-Bold.woff) format('woff');
}